import axios from 'axios';
import { assignCancelToken } from '@/api/utils';
import route from '@/router/route';
import { backend } from '.';

interface SearchFilters {
    universityId?: string;
    universityName?: string;
    courseId?: string;
    courseName?: string;
    documentType?: number[];
    sortTypeDocuments?: string;
    sortTypeCourses?: string;
    sortTypeFlashcards?: string;
    minSemester?: number;
    maxSemester?: number;
    p?: number;
    perPage?: number;
}

export function getDocuments(filters: SearchFilters, page = 1) {
    return backend.get(route('search.results').toString(), {
        params: {
            ...filters,
            content: 'documents',
            p: page,
        },
    });
}

export function getFlashcards(filters: SearchFilters, page = 1) {
    return backend.get(route('search.results').toString(), {
        params: {
            ...filters,
            content: 'flashcards',
            p: page,
        },
    });
}

export function joinCourse(courseId: number, fromCoursePage = false) {
    return backend.post(route('course.join', { id: courseId }), {
        fromCoursePage,
    });
}

export function leaveCourse(courseId: number, keepFollowedDocuments = false) {
    return backend.post(route('course.leave', { id: courseId }), {
        keepFollowedDocuments,
    });
}

export function submitExamSuggestion(courseId: number, date: string) {
    return backend
        .post(route('course.exam.suggest', { id: courseId }), {
            courseId,
            date,
        })
        .then(() => true);
}

export function sendExamReport(courseId: number, message: string) {
    return backend.post(route('course.exam.report', { id: courseId }), { message });
}

export function getFeed(courseId: number, page: number) {
    return backend.get(route('course.feed', { id: courseId, page }));
}

export function searchCourses(keyword: string, payload = {}) {
    Object.assign(payload, { query: keyword });

    const cancelToken = axios.CancelToken.source();
    const promise = backend.get(route('course.index', payload), {
        cancelToken: cancelToken.token,
    });

    return assignCancelToken(promise, cancelToken);
}

export function createCourse(payload: { course_name: number; uniid: number; number: number }) {
    return backend.post(route('create.course'), payload);
}

export function getUserCourses() {
    return backend.post(route('upload.list.contexts'));
}

export function orderCourses(order: string[]) {
    return backend.post(route('course.memberships.order'), { order });
}

export function getCourseById(courseId: number) {
    return backend.get<{ id: number; name: string; uniid: number }>(route('course.id', { courseId }));
}
