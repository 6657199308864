import type { InjectionKey } from 'vue';
import type { Tab } from '@/components/TabsPanel/types';
import type { ChatUserResource } from '@/api/gateway/messages/resources/ChatUserResource';

/*
 * this file contains keys used for provide/inject usage to better organize them.
 * see: https://vuejs.org/guide/components/provide-inject.html#working-with-symbol-keys
 *
 * name them `provide*Key`
 */
export const provideInputLabelKey: InjectionKey<string | null> = Symbol('input-label');

export const provideRegisterTabKey: InjectionKey<(tab: Tab) => void> = Symbol('register-tab');
export const provideUpdateTabKey: InjectionKey<(tab: Tab) => void> = Symbol('update-tab');
export const provideUnregisterTabKey: InjectionKey<(id: string) => void> = Symbol('unregister-tab');
export const provideActiveTabIdKey: InjectionKey<string | null> = Symbol('active-tab-id');
export const provideGetChatUsersKey: InjectionKey<() => Record<number, ChatUserResource>> = Symbol('get-chat-users');
export const provideIsDashboardKey: InjectionKey<boolean | null> = Symbol('is-dashboard');
export const provideGuestSelectedUniId: InjectionKey<number> = Symbol('guest-selected-uni-id');
